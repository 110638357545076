import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '@config/Validators/cake.validator';
import { graphql } from 'gatsby';
import { config } from '@config';
import Language from '@unicat/i18n/LanguageContext';
import t from '@unicat/i18n/Translate';
import { SEO, MultiLanguage, PreLoad, PreConnect, TwitterCard, OpenGraph, Experimental } from '@unicat/Seo';
import CakePageContent from '@unicat/CakePageContent';
import Footer from '@unicat/Footer';

export const queryImages = graphql`
  query queryCakeImages($images: [String!]!) {
    allFile(filter: { relativePath: { in: $images } }) {
      edges {
        node {
          name
          extension
          size
          base
          absolutePath
          relativePath
          relativeDirectory
          publicURL
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`;

const CakeDetailsPage = ({ pageContext, data, location }) => {
  const { cake } = pageContext;
  const publicURL = data.allFile.edges[0].node.publicURL;
  cake['imgLink'] = data.allFile.edges.map((e) => e.node.childImageSharp.fluid);

  const language = useContext(Language);
  const { lang } = language ?? { lang: config.DefaultLanguage };
  const { title, description, keywords } = cake;
  const metaKeywords = (keywords || []).join(', ');

  console.dir(cake);

  return (
    <>
      <>
        <SEO title={t(title)} description={t(description)} keywords={metaKeywords} lang={lang} />
        <MultiLanguage lang={lang} location={location} />
        <TwitterCard title={t(title)} description={t(description)} image={publicURL} />
        <OpenGraph title={t(title)} description={t(description)} image={publicURL} />
        <PreLoad />
        <PreConnect />
        <Experimental />
      </>
      <div className={'default-container'}>
        <CakePageContent image={cake} />
        <Footer />
      </div>
    </>
  );
};

CakeDetailsPage.defaultProps = {};
CakeDetailsPage.propTypes = {
  pageContext: PropTypes.shape({
    cake: PropTypes.shape({
      ...propTypes,
      imgLink: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
      /* SEO */
      title: PropTypes.string,
      description: PropTypes.string,
      keywords: PropTypes.arrayOf(PropTypes.string),
      image: PropTypes.element /* page image */,
    }),
  }),
  data: PropTypes.shape({
    allFile: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            publicURL: PropTypes.string,
          }),
        }),
      ),
    }),
  }),
  location: PropTypes.any,
};

export default CakeDetailsPage;
